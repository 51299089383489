import {LOGOUT_USER} from '../actions/auth';
import {FETCH_DASHBOARD_ARMOUR} from "../actions/dashboards";

const INITIAL_STATE = {
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_DASHBOARD_ARMOUR}_PENDING`:
      // esse status true tem q estar em outro formato, para nao travar o application
      // list....
      return {
        ...state,
        isLoading: action.payload.loading,
        sealStatus: false,
        status: true,
      };
    case `${FETCH_DASHBOARD_ARMOUR}_FULFILLED`: {
      const data = {};
      data[action.payload.name] = action.payload;
      if (data.undefined) {
        return {
          isLoading: false,
          sealStatus: false,
          status: false,
        };
      }
      if (data.armour) {
        return {
          ...data,
          isLoading: false,
          sealStatus: true,
          status: true,
        };
      }
      return {
        ...state,
        rejected: true,
        status: true,
        sealStatus: true,
        isLoading: true,
      };
    }
    case `${FETCH_DASHBOARD_ARMOUR}_REJECTED`:
      return {
        ...state,
        rejected: true,
        status: true,
        sealStatus: true,
        isLoading: true,
      };

    case LOGOUT_USER:
      return INITIAL_STATE;

    default:
      return state;
  }
};
