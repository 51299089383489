import PropTypes from 'prop-types'
import React from 'react'
import {Card, ContentFilter} from '../../components'
import {withAccountId} from '../../containers/AccountContainer'
import {fetchApplications} from '../../actions/applications'
import {connect} from 'react-redux'
import debounce from 'debounce'
import {Cancel} from '@material-ui/icons'

class ApplicationsFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      search: '',
    }

    this.handleContentFilter = this
      .handleContentFilter
      .bind(this)
    this.handleSearch = this
      .handleSearch
      .bind(this)
    this.clearSearch = this
      .clearSearch
      .bind(this)
    this.doSearch = debounce(this.doSearch, 600)
  }

  clearSearch() {
    this.setState({
      search: '',
    }, this.doSearch())
  }

  handleSearch({target}) {
    this.setState({
      search: target.value,
    }, this.doSearch)
  }

  handleContentFilter(item) {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        ...item,
      },
    }, this.doSearch)
  }

  doSearch() {
    if (!this.props.isLoading) {
      this
        .props
        .fetchApplications(this.props.accountId, this.state)
    }
  }

  render() {
    const {status} = this.props
    let infoReturn = ''

    if (status) {
      infoReturn = (
        <Card>
          <div className="application-filter">
            <div className="item">
              <div className="search">
                {this.state.search && <span className="clear-search" onClick={this.clearSearch}>
                  <Cancel/>
                </span>
                }
                <input
                  type="text"
                  placeholder="Url da aplicação"
                  value={this.state.search}
                  onChange={this.handleSearch}
                />
              </div>
            </div>

            <div className="item">
              <ContentFilter
                title="Status do Selo"
                name="seal_status"
                items={{
                  suspended: 'Sem selo',
                  vulnerable: 'Vulnerável',
                  ok: 'Certificado',
                }}
                handleContentFilter={this.handleContentFilter}
              />
            </div>
          </div>
        </Card>
      )
    } else {
      infoReturn = (
        <div></div>
      )
    }
    return (
      <div>
        {infoReturn}
      </div>
    )
  }
}

ApplicationsFilter.propTypes = {
  fetchApplications: PropTypes.func,
  accountId: PropTypes.string,
  status: PropTypes.bool,
}

const mapStateToProps = ({dashboardArmour}) => ({
  isLoading: dashboardArmour.isLoading,
  status: dashboardArmour.status,
  ...dashboardArmour.armour,
})

export default connect(mapStateToProps, {fetchApplications})(withAccountId(ApplicationsFilter))
