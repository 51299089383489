import PropTypes from 'prop-types'
import React from 'react'
import {Card, ExpiringCertificates, GenericPieChart, Loading} from '../../components'
import {fetchDashboardSSL} from '../../actions/dashboards'
import {connect} from 'react-redux'
import find from 'lodash/find'
import {withAccountId} from '../AccountContainer'

class DashboardSSLWithoutSummaryProducts extends React.Component {
  constructor(props) {
    super(props)
    this.getChartData = this.getChartData.bind(this)
  }

  componentDidMount() {
    const accountId = this.props.accountId

    if (!this.props.isLoading) {
      this.props.fetchDashboardSSL(accountId)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.accountId !== newProps.accountId) {
      this.props.fetchDashboardSSL(newProps.accountId)
    }
  }

  getChartData(name) {
    const chart = this.getChart(name)

    if (!chart.data) {
      return []
    }

    return chart.data
  }

  getChart(name) {
    if (!this.props.charts) {
      return []
    }

    return find(this.props.charts, {name})
  }

  render() {
    return (
      <article className="dashboard">
        <Loading loading={this.props.isLoading}/>

        <Card>
          <div className="inline-items">
            <GenericPieChart
              title="Nota"
              subtitle={this.getChart('rating').subtitle}
              data={this.getChartData('rating')}
            />

            <ExpiringCertificates
              data={this.getChartData('certificates-expiring')}
            />
          </div>
        </Card>
      </article>
    )
  }
}

DashboardSSLWithoutSummaryProducts.defaultProps = {
  showFilters: true,
}

DashboardSSLWithoutSummaryProducts.propTypes = {
  accountId: PropTypes.string.isRequired,
  fetchDashboardSSL: PropTypes.func,
  charts: PropTypes.array,
  isLoading: PropTypes.bool,
}

const mapStateToProps = ({dashboardSSL}) => {
  return ({
    isLoading: dashboardSSL.isLoading,
    ...dashboardSSL.ssl,
    dashboardSSL,
  })
}

export default connect(mapStateToProps, {fetchDashboardSSL})(withAccountId(DashboardSSLWithoutSummaryProducts))
