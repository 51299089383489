import { fetch } from '../services/fetch'
import { LOGOUT_USER } from './auth'
import { push } from 'connected-react-router'
import { fetchSummaryProducts } from "../actions/products";
import { fetchOrganizationUsers } from "../actions/organizations";

export const FETCH_MRU = 'account/FETCH_MRU'
export const FETCH_ACCOUNT_ANALYZE = 'account/FETCH_ACCOUNT_ANALYZE'

export const CONSOLIDATED_ACCOUNT = {
  id: '00000000-0000-0000-0000-000000000000',
  name: 'Consolidado',
  type: 'consolidated',
}

const CURRENT_ORG = 0

export function switchAccount (account) {
  return (dispatch) => {
    fetch('/mru-accounts', {
      method: 'POST',
      body: JSON.stringify({ account: account.id }),
    })
      .then(res => res.json())
      .then(
        ({ results }) => {
          dispatch({ type: FETCH_MRU, payload: results })
          dispatch(fetchSummaryProducts(results[CURRENT_ORG].id))
          dispatch(fetchOrganizationUsers(results[CURRENT_ORG].id))
        }
      )
      .then(dispatch(push('/dashboard')))
      .catch(err => console.error('opss'))
  }
}

export function fetchMruAccounts (token) {
  return (dispatch) => {
    fetch('/mru-accounts', { token })
      .then(res => res.json())
      .then(
        ({ results }) => {
          dispatch({ type: FETCH_MRU, payload: results })
          dispatch(fetchSummaryProducts(results[CURRENT_ORG].id))
          dispatch(fetchOrganizationUsers(results[CURRENT_ORG].id))
        }
      )
      .catch(() => dispatch({ type: LOGOUT_USER }))
  }
}

export function fetchAccountAnalyze (account_id) {
  return (dispatch) => {
    fetch(`/accounts/${account_id}/analyze`)
      .then(res => {
        return res.json()
      })
      .then((results) => {
        dispatch({ type: FETCH_ACCOUNT_ANALYZE, payload: results })
      })
      .catch(err => console.error('opss analyze'))
  }
}
