import PropTypes from 'prop-types';
import React from 'react';
import {Card, CardGroup, FilterBox} from '../../components';
import {fetchCertificateByFilter} from '../../actions/certificates';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';

const CertificatesFilters = (props) => (
  <>
    <Box sx={{marginLeft: '1rem', marginTop: '2rem', marginBottom: '1rem', display: 'flex'}}>
      <Typography variant='h4'>
        <strong>Selecione para filtrar:</strong>
      </Typography>
    </Box>
    <CardGroup>
      {props.filters.map((item, key) =>
        <Card key={key}>
          <FilterBox
            number={item.value}
            active={props.filter === item.filter}
            onClick={() =>
              props.fetchCertificateByFilter(props.accountId, {filter: item.filter})
            }
            unfocused={!!props.filter}
            className={item.filter}
          >
            {item.title}
          </FilterBox>
        </Card>
      )}
    </CardGroup>
  </>
);

CertificatesFilters.propTypes = {
  accountId: PropTypes.string,
  filter: PropTypes.string,
  filters: PropTypes.array,
  fetchCertificateByFilter: PropTypes.func,
};

CertificatesFilters.defaultProps = {
  filters: [],
};

const mapStateToProps = ({certificates, dashboardSSL}, {location}) => ({
  filter: certificates.filter,
  ...dashboardSSL.ssl,
  location,
});

export default connect(mapStateToProps, {fetchCertificateByFilter, push})(CertificatesFilters);
