import PropTypes from 'prop-types'
import React from 'react'
import {IconMdLock} from '../../sb2w-icons/md'

const divisorColors = {
  'site-blindado': '#74B61B',
  'trust-sign': '#072944',
  blindagem: '#74B61B',
}

export const StoreBanner = ({
                              catalog,
                              // website,
                              // websiteTitle,
                              bannerURL,
                              title,
                              description,
                              backgroundColor = '#000000',
                              height = '26vw'
                            }) => (
  <section>
    <article
      className={`store-banner banner-${catalog}`} style={{height: height, backgroundColor: backgroundColor}}
    >
      <div className="content-inner">
        <div className="title">
          <div
            className="icon-circle"
            style={{
              backgroundColor:
                catalog === 'trust-sign'
                  ? '#FFFFFF'
                  : divisorColors[catalog],
            }}
          >
            <IconMdLock
              fill={
                catalog === 'trust-sign'
                  ? '#072944'
                  : 'currentColor'
              }
            />
          </div>
          <h1>{title}</h1>
        </div>

        <div className="description">
          <div dangerouslySetInnerHTML={{__html: description}}/>
        </div>
      </div>
    </article>

    {/*<article className="store-divisor" style={{backgroundColor: divisorColors[catalog]}}>*/}
    {/*    <span className="title"> O que você deseja proteger?</span>*/}
    {/*    <a*/}
    {/*        href={website}*/}
    {/*        className="link"*/}
    {/*        target="_blank"*/}
    {/*    >*/}
    {/*        {websiteTitle}*/}
    {/*    </a>*/}
    {/*</article>*/}
  </section>
)

StoreBanner.propTypes = {
  catalog: PropTypes.string,
  website: PropTypes.string,
  websiteTitle: PropTypes.string,
  bannerURL: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  height: PropTypes.string
}
