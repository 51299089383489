import PropTypes from 'prop-types';
import React from 'react';
import {Card, CardGroup, FilterBox} from '../../components';
import {fetchCertificateByFilter} from '../../actions/certificates';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';

const UptimeFilters = (props) => (
  <CardGroup>
    {props.filters.map((item, key) =>
      <Card key={key}>
        <FilterBox
          number={item.value}
          active={props.filter === item.filter}
          onClick={() =>
            props.fetchCertificateByFilter(props.accountId, {filter: item.filter})
          }
          unfocused={!!props.filter}
          className={item.filter}
        >
          {item.title}
        </FilterBox>
      </Card>
    )}
  </CardGroup>
);

UptimeFilters.propTypes = {
  accountId: PropTypes.string,
  filter: PropTypes.string,
  filters: PropTypes.array,
  fetchCertificateByFilter: PropTypes.func,
};

UptimeFilters.defaultProps = {
  filters: [],
};

const mapStateToProps = ({certificates, dashboardSSL}, {location}) => ({
  filter: certificates.filter,
  ...dashboardSSL.ssl,
  location,
});

export default connect(mapStateToProps, {fetchCertificateByFilter, push})(UptimeFilters);
