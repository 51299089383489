import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  IconMdBusiness,
  IconMdDeviceHub,
  IconMdExitToApp,
  IconMdGroup,
  IconMdLock,
  IconMdSecurity,
  IconMdSettings,
  IconMdShoppingBasket,
  IconMdDashboard
} from '../sb2w-icons/md'
import { IconSbPenTest } from '../sb2w-icons/sb'
import { onlyAdmin, userIsAuthenticatedRedir } from '../services/auth'
import { Typography } from '@mui/material'
import versionTXT from '../../version.txt'

const UserMenu = onlyAdmin(() => (
  <Item
    to="/users"
    icon={IconMdGroup}
    name="Usuários"
  />
))

// const PentestList = userIsAuthenticated(() => (
//   <ItemMenu
//     to="/pentest/list"
//     icon={IconSbPenTest}
//     name="Pentest"
//     beta
//   />
// ))

// const WafPage = userIsAuthenticated(() => (
//   <ItemMenu
//     to="/waf/"
//     icon={IconSbWaf}
//     name="WAF"
//     beta
//   />
// ))

const SystemMenu = onlyAdmin(() => (
  <>
    <ItemMenu
      to="/system"
      icon={IconMdSettings}
      name="Sistema"
      beta
    />
    <ItemMenu
      to="/backoffice"
      icon={IconMdExitToApp}
      name="Backoffice"
      beta
    />
  </>
))


const Item = (props) => {
  const {
    name,
    ...linkProps
  } = props
  delete linkProps.icon

  return (
    <li>
      <NavLink {...linkProps} activeClassName="active">
        <props.icon />

        <p>
          {name}
        </p>
      </NavLink>
    </li>
  )
}

const mapStateToProps = ({ auth }) => ({ roleUser: auth.data.role })

export const ItemMenu = connect(mapStateToProps)(({
  roleUser,
  disabled,
  beta,
  ...props
}) => {
  const isDisabled = () => (disabled || (beta && roleUser !== 'admin'))

  delete props.dispatch
  return (
    !isDisabled()
      ? <Item
        {...props}
        className={(beta && roleUser === 'admin'
          ? 'beta'
          : '')}
        disabled={isDisabled()}
      />
      : null)
})

function Notes () {
  const [text, setText] = React.useState();
  fetch(versionTXT)
    .then((response) => response.text())
    .then((textContent) => {
      setText(textContent);
    });
  return text || "Loading...";
}


export const Menu = userIsAuthenticatedRedir(() => (
  <>
    <ul className="nav">
      <ItemMenu to="/dashboard" icon={IconMdDashboard} name="Dashboard" />
      <ItemMenu to="/store/site-blindado" icon={IconMdShoppingBasket} name="Loja" />
      <ItemMenu to="/certificates/domains" icon={IconMdLock} name="Certificados SSL" />
      <ItemMenu to="/armour/applications" icon={IconMdSecurity} name="Blindagem" />
      {/* <WafPage /> */}
      {/*<ItemMenu to="/pentest/list" icon={IconSbPenTest} name="Pentest" beta/>*/}
      <Divider />
      <ItemMenu to="/devices/domains" icon={IconMdDeviceHub} name="Domínios" />
      <ItemMenu to="/organizations" icon={IconMdBusiness} name="Organizações" />
      <UserMenu />
      {/*<SystemMenu/>*/}
    </ul>

    {/*<Typography*/}
    {/*  variant="caption"*/}
    {/*  style={{position: 'fixed', bottom: 0, textAlign: 'center', width: '10rem'}}*/}
    {/*>*/}
    {/*  {Notes()}*/}
    {/*</Typography>*/}
  </>
))

const Divider = () => <li className="menu-divider"></li>

Item.propTypes = {
  icon: PropTypes.func,
  name: PropTypes.string,
}
