import PropTypes from 'prop-types';
import React from 'react';
import {Cell, Legend, Pie, PieChart, Tooltip} from 'recharts';
import {AboutSealStatus, Help} from '../../components';
import sumBy from 'lodash/sumBy';

export const SealStatusChart = (props) => {
  const colors = ['#fd4d4d', '#ffa8a8', '#7dc671'];

  return (
    <div className="chart">
      <h1 className="chart-title">
        Status
        <Help title="Status dos domínios">
          <AboutSealStatus/>
        </Help>
      </h1>
      {!!props.data.length &&
        <div className="chart-pie-total">{sumBy(props.data, 'value')} <h6>Domínio(s)</h6></div>
      }
      <PieChart width={props.graphWidth} height={230}>
        <Pie
          dataKey="value"
          data={props.data}
          cy={90}
          innerRadius={60}
          outerRadius={80}
          paddingAngle={0}
        >
          {props
            .data
            .map((entry, index) => <Cell key={index} fill={colors[index % colors.length]}/>)
          }
        </Pie>
        <Tooltip/>
        <Legend/>
      </PieChart>
    </div>
  );
};

SealStatusChart.defaultProps = {
  title: 'Status',
  data: [],
  graphWidth: 400
};

SealStatusChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  graphWidth: PropTypes.number
};
