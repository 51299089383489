import React, {useEffect, useState} from 'react'
import store from 'store'
import {Box, Button, Chip, IconButton, List, ListItem, ListItemText, Modal, Pagination, Typography,} from "@mui/material";
import {Icon, Loading} from '../../components';
import {fetch} from '../../services/fetch'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from "react-router-dom";
import {formatDistanceToNow} from "date-fns";
import {ptBR} from "date-fns/locale";
import DeleteIcon from "@mui/icons-material/Delete";
import {updateNotificationsCount} from '../../actions/auth'
import {setMessage, SUCCESS} from '../../modules/messages';
import {faMailBulk} from "@fortawesome/free-solid-svg-icons";


export const NotificationList = ({pageSizeDefault = 10}) => {

  const PRIORITY_COLOR = {
    0: 'error',
    1: 'error',
    2: 'warning',
    3: 'warning',
    4: 'info',
    5: 'info',
  }

  const PRIORITY_LABEL = {
    0: 'Importante',
    1: 'Importante',
    2: 'Atenção',
    3: 'Atenção',
    4: 'Informação',
    5: 'Informação',
  }

  const userData = useSelector(state => state.user)

  const history = useHistory()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userNotifications, setUserNotifications] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeDefault);

  useEffect(() => {
    if (userData) {
      fetchUserNotifications()
    }
    if (!userData) {
      return history.push('/store/products')
    }
  }, [])

  const fetchUserNotifications = async (page) => {
    fetch(`/users/${userData.results.id}/notifications?page_size=${pageSize}&page=${page ? page : 1}`)
      .then((response) => response.json())
      .then((res) => {
        setUserNotifications(res.results)
        setCurrentPage(res.page)
        setCount(res.count)
      })
  }


  const notificationTime = (notificationDispath) => {
    return formatDistanceToNow(new Date(notificationDispath), {
      addSuffix: true,
      locale: ptBR,
    })
  }

  const notificationIsDispatched = (userNotification) => {
    return userNotification.status === "DISPATCHED";
  }

  const editUserNotificationStatus = async (idUserNotification, status) => {
    try {
      const response = await fetch(
        `/users/${userData.results.id}/notifications/${idUserNotification}`,
        {
          method: 'PATCH',
          body: JSON.stringify({status: status}),
        }
      )
      const updatedNotification = await response.json()
      return updatedNotification;
    } catch (error) {
      throw new Error(`Erro ao atualizar notificação: ${error.message}`)
    }
  }

  const handleOpen = async (userNotification) => {
    setSelectedNotification(userNotification);
    if (notificationIsDispatched(userNotification)) {
      setLoading(true)
      const userNotifyNumber = Number(store.get('userNotifyNumber'))
      dispatch(updateNotificationsCount(userNotifyNumber - 1))
      try {
        const updatedNotification = await editUserNotificationStatus(userNotification?.id, "VIEWED")
        setUserNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === updatedNotification.id
              ? {...notification, ...updatedNotification}
              : notification
          )
        )
      } catch (error) {
        console.log(error)
      }

      setLoading(false)
    }
    setOpen(true);
  };

  const handleRemove = async (id) => {
    setLoading(true);
    try {
      await editUserNotificationStatus(id, "CLOSED")
      await fetchUserNotifications(currentPage)
      dispatch(setMessage(SUCCESS, 'Notificação removida com sucesso.'))
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNotification(null);
  };

  const handlePageChange = async (_, page) => {
    setCurrentPage(page);
    setLoading(true);
    fetchUserNotifications(page)
    setLoading(false);
  };

  return (
    <>
      <List>
        <Loading loading={loading}/>
        {(
          userNotifications?.length === 0 ?
            (
              <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center', height: '300px'}}>
                <Typography variant="h5" sx={{margin: 'auto'}}>
                  Você não possui notificações
                </Typography>
              </Box>
            ) :
            userNotifications?.map((userNotification) => (
              <ListItem
                key={userNotification.id}
                sx={{
                  mb: 1,
                  backgroundColor: (notificationIsDispatched(userNotification)) ? "#e5ffe0" : "#fff",
                  borderRadius: 2,
                  boxShadow: 1,
                  cursor: "pointer",
                }}
                onClick={() => handleOpen(userNotification)}
              >
                <Icon name="mail" icon={faMailBulk} style={{marginRight: "2rem"}}/>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="span" sx={{fontWeight: "bold"}}>
                      {userNotification.notification.title}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        variant="caption"
                        component="span"
                        color="text.disabled"
                        sx={{display: "block", mt: 0, fontSize: "1.1rem"}}
                      >
                        {notificationTime(userNotification?.notification?.dispatch_at)}
                      </Typography>
                    </>
                  }
                />
                <Chip
                  label={PRIORITY_LABEL[userNotification.notification.priority]}
                  color={PRIORITY_COLOR[userNotification.notification.priority]}
                  size="small"
                  sx={{mr: 2, minWidth: 80, textAlign: "center", fontSize: "1rem", fontWeight: "bold"}}
                />

                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleRemove(userNotification.id);
                  }}
                  sx={{
                    width: 35,
                    height: 35,
                    marginRight: '1rem',
                    "&:hover": {
                      backgroundColor: "#00000020",
                    },
                  }}
                >
                  <DeleteIcon fontSize='large'/>
                </IconButton>
              </ListItem>
            )))}
      </List>

      {count > pageSizeDefault && (
        <Box sx={{mt: 2, display: "flex", justifyContent: "center"}}>
          <Pagination
            count={Math.ceil(count / pageSize)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          {selectedNotification && (
            <>
              <Typography
                id="modal-title"
                variant="h4"
                sx={{fontWeight: "bold", mb: 2}}
              >
                {selectedNotification.notification.title}
              </Typography>
              <Typography variant="body1" sx={{mb: 2, fontSize: '1.5rem'}}>
                {selectedNotification.notification.message}
              </Typography>
              <Typography variant="caption" color="text.disabled" sx={{fontWeight: 'bold', fontSize: "1.1rem"}}>
                {notificationTime(selectedNotification?.notification?.dispatch_at)}
              </Typography>
              <Box textAlign="right" mt={2}>
                <Button variant="contained" sx={{color: "white", fontWeight: "bold"}} onClick={handleClose}>
                  Fechar
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}
