import PropTypes from 'prop-types'
import { ProductsSummary } from '../containers'

export const MyProducts = (props) => {

  return (
    <article>
      <h1 className="chart-title">
        Meus Produtos
      </h1>
      <ProductsSummary productType={props.productType} isMainDashboard={props.isMainDashboard} />
    </article>
  )
}

MyProducts.propTypes = {
  productType: PropTypes.any,
  isMainDashboard: PropTypes.bool
}
