import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Card, SealStatusChart, VulnerabilitiesChart, VulnerabilitiesHistoryChart} from '../../components'
import {fetchDashboardArmour} from '../../actions/dashboards'
import {withAccountId} from '../AccountContainer'
import find from 'lodash/find'

class DashboardArmour extends React.Component {
  constructor(props) {
    super(props)
    this.getChart = this
      .getChart
      .bind(this)
  }

  componentDidMount() {
    if (!this.props.isLoading) {
      this
        .props
        .fetchDashboardArmour(this.props.accountId)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.accountId !== newProps.accountId) {
      this
        .props
        .fetchDashboardArmour(newProps.accountId)
    }
  }

  getChart(name) {
    if (!this.props.charts) {
      return []
    }

    const charts = find(this.props.charts, {name})
    return charts || {}
  }

  render() {
    if (this.props.status === false) {
      return (
        <div>
          <Card className="waf-description">
            <h2>Organização Suspensa</h2>
            <p>
              O serviço de blindagem está desativado pois esta organização não está ativa.
              Para mais informações, entre em contato com o time de relacionamento através do
              email: <a href="mailto:relacionamento@siteblindado.com.br">relacionamento@siteblindado.com.br</a> ou pelo telefone 4003-2122
            </p>
          </Card>
        </div>
      )
    }

    return (
      <div className="dashboard">
        <Card>
          <div className="inline-items">
            {this.props.status && !this.props.isLoading &&
              <React.Fragment>
                <VulnerabilitiesChart
                  title={this
                    .getChart('vulnerable')
                    .title}
                  data={this
                    .getChart('vulnerable')
                    .data || []}
                  graphWidth={this.props.isMainDashboard ? 250 : 400}
                />

                <VulnerabilitiesHistoryChart
                  title={this
                    .getChart('vulnerable-history')
                    .title}
                  data={this
                    .getChart('vulnerable-history')
                    .data || []}
                  graphWidth={this.props.isMainDashboard ? 250 : 400}
                />

                <SealStatusChart
                  title={this
                    .getChart('seal-status')
                    .title}
                  data={this
                    .getChart('seal-status')
                    .data || []}
                  graphWidth={this.props.isMainDashboard ? 250 : 400}
                />
              </React.Fragment>
            }
          </div>
        </Card>
      </div>
    )
  }
}

DashboardArmour.defaultProps = {
  charts: [],
  isLoading: false,
  rejected: false,
  status: false,
  isMainDashboard: false
}

DashboardArmour.propTypes = {
  fetchDashboardArmour: PropTypes.func,
  accountId: PropTypes.string,
  charts: PropTypes.array,
  isLoading: PropTypes.bool,
  rejected: PropTypes.bool,
  status: PropTypes.bool,
  isMainDashboard: PropTypes.bool
}

const mapStateToProps = ({dashboardArmour}) => ({
  isLoading: dashboardArmour.isLoading,
  status: dashboardArmour.status,
  ...dashboardArmour.armour,
})

export default connect(mapStateToProps, {fetchDashboardArmour})(withAccountId(DashboardArmour))
