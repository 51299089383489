import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Choices, TableWrap,} from '../../components'
import {RoleSelector} from '../../containers'
import {onlyAdmin} from '../../services/auth'
import {clearOrganizationUsers, deleteOrganizationUser, fetchOrganizationUsers, saveOrganizationUser,} from '../../actions/organizations'
import moment from 'moment'
import {withAccountId} from "../AccountContainer";
import {NavLink} from "react-router-dom";

const roles = {
  owner: 'Proprietário',
  admin: 'Administrador',
  user: 'Usuário',
}

const Role = ({role}) => (
  <span>{roles[role]}</span>
)

const Badge = ({label, children}) => (
  <div style={{padding: '0.5rem 2rem', backgroundColor: "#ccc", borderRadius: '5rem', width: 'fit-content', margin: 'auto'}}>{children}</div>
)

const ChangeRole = onlyAdmin((props) =>
    <Choices
      items={[
        {label: 'Comercial/Marketing', value: 'Comercial/Marketing'},
        {label: 'Técnico', value: 'Técnico'},
        {label: 'Financeiro', value: 'Financeiro'},
        {label: 'Proprietário', value: 'Proprietário'},
        {label: 'Outro', value: 'Outro'},
      ]}
      itemChecked={props.item}
      onClick={(choice) => {
        props.saveOrganizationUser(props.match.params.id, props.obj.user.id, {profile: choice})
      }}
    />,
  (props) =>
    <p> {props.item} </p>
)

class DashboardOrganizationUsersList extends React.Component {
  constructor(props) {
    super(props)
    this.simpleModel = {
      user: {
        name: 'Nome',
        renderer: (item) => (
          `${item.first_name} ${item.last_name || ''}`
        ),
      },
      'user.email': {name: 'E-mail'},
      'user.phone': {name: 'Telefone'},
      role: {
        name: 'Perfil',
        renderer: (item, obj) => {
          return (
            <RoleSelector
              role={obj.role}
              id={obj.user.id}
              organizationId={this.props.accountId}
            />
          )
        },
      },
    }

    this.model = {
      user: {
        name: 'Nome',
        renderer: (item) => (
          `${item.first_name} ${item.last_name || ''}`
        ),
      },
      'user.email': {name: 'E-mail'},
      'user.phone': {name: 'Telefone'},
      role: {
        name: 'Perfil',
        renderer: (item, obj) => {
          return (
            <Badge><Role role={item}/></Badge>
          )
        },
      },
      profile: {
        name: 'Cargo',
        renderer: (item, obj) => (
          <Badge>{item}</Badge>
        ),
      },
      'user.last_login': {
        name: 'Último Login',
        renderer: (date) => <span>
          {date
            ? moment(date).format('DD/MM/YYYY HH:mm')
            : '-'
          }
        </span>,
      },
    }
  }

  render() {
    const currentOrganization = this.props.userData.organizations[this.props.accountId]
    const isUserStaff = this.props.userData.role === 'admin'

    const model = () => {
      if (isUserStaff) {
        return this.model
      }

      if (currentOrganization && currentOrganization.role === 'user') {
        return this.simpleModel
      }

      return this.model

    }

    return (
      <div>
        <TableWrap
          model={model()}
          source={this.props.organizationUsers}
          actionFetch={this.props.fetchOrganizationUsers}
          location={this.props.location}
          accountId={this.props.accountId}
        />
        <NavLink className="button-primary" style={{marginLeft: '4rem'}} to={`/organizations/${this.props.accountId}/users`}>Editar os Usuários</NavLink>
      </div>
    )
  }
}

DashboardOrganizationUsersList.defaultProps = {
  id: '',
  userId: '',
  params: {},
  location: {},
  organizationUsers: {},
  fetchOrganizationUsers: () => {
  },
  deleteOrganizationUser: () => {
  },
  clearOrganizationUsers: () => {
  },
  saveOrganizationUser: () => {
  },
  userData: {},
  accountId: '',
}

DashboardOrganizationUsersList.propTypes = {
  id: PropTypes.string,
  userId: PropTypes.string,
  params: PropTypes.object,
  location: PropTypes.object,
  organizationUsers: PropTypes.object,
  fetchOrganizationUsers: PropTypes.func,
  deleteOrganizationUser: PropTypes.func,
  clearOrganizationUsers: PropTypes.func,
  saveOrganizationUser: PropTypes.func,
  userData: PropTypes.object,
  accountId: PropTypes.string.isRequired,
}

const mapStateToProps = ({auth, organizationUsers}) => {
  return ({
    userId: auth.data.user.id,
    userData: auth.data,
    organizationUsers,
  })
}

export default connect(mapStateToProps, {
  fetchOrganizationUsers,
  deleteOrganizationUser,
  clearOrganizationUsers,
  saveOrganizationUser,
})(withAccountId(DashboardOrganizationUsersList))
