import React from 'react'
import {Box, Typography,} from "@mui/material";
import {NotificationList} from "../../components/notifications/NotificationList";


export const IndexNotifications = () => {
  return (
    <Box sx={{p: 3, backgroundColor: "#f9f9f9", height: "100vh"}}>
      <Typography variant="h5" sx={{mt: 2, mb: 2, ml: 1, fontSize: '3rem', fontWeight: 'bold'}}>
        Notificações
      </Typography>
      <NotificationList/>
    </Box>
  );
}
