import PropTypes from 'prop-types'
import React from 'react'
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from 'recharts'
import * as css from '../../styles/settings/variables'

export const VulnerabilitiesChart = (props) => (
  <div className="chart">
    <h1 className="chart-title">
      {props.title}
    </h1>

    <BarChart
      width={props.graphWidth}
      height={230}
      data={props.data}
      barSize={50}
      margin={{top: 5, right: 30, left: 20, bottom: 5}}
      isAnimationActive={false}
    >
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid strokeDasharray="1 1"/>
      <Tooltip/>
      <Legend width={props.graphWidth}/>
      <Bar stackId="a" dataKey="value[4]" name="Nível 5" fill={css.$level5} isAnimationActive={false}/>
      <Bar stackId="a" dataKey="value[3]" name="Nível 4" fill={css.$level4} isAnimationActive={false}/>
      <Bar stackId="a" dataKey="value[2]" name="Nível 3" fill={css.$level3} isAnimationActive={false}/>
      <Bar stackId="a" dataKey="value[1]" name="Nível 2" fill={css.$level2} isAnimationActive={false}/>
      <Bar stackId="a" dataKey="value[0]" name="Nível 1" fill={css.$level1} isAnimationActive={false}/>
    </BarChart>
  </div>
)

VulnerabilitiesChart.defaultProps = {
  title: 'Vulnerabilidades',
  data: [],
  graphWidth: 400
}

VulnerabilitiesChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  graphWidth: PropTypes.number
}
