import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Card, GenericPieChart, Loading, MyProducts } from '../../components'
import { withAccountId } from '../AccountContainer'
import { connect } from 'react-redux'
import sumBy from 'lodash/sumBy'

const chartColors = {
  CANCELADO: '#2D343D',
  DISPONÍVEL: '#7DC671',
  'EM USO': '#65C4ED',
  NEGADO: '#FD4D4D',
  PENDENTE: '#FEE549',
  USADO: '#BBBBBB',
  EXPIRADO: '#F3F3F3',
}

const data = results => {
  const sumProducts = type => (
    sumBy(results, r => r.quantities[type])
  )

  const sumResult = {
    pending: {
      value: sumProducts('pending'),
      name: 'Pendente',
    },
    available: {
      value: sumProducts('available'),
      name: 'Disponível',
    },
    canceled: {
      value: sumProducts('canceled'),
      name: 'Cancelado',
    },
    denied: {
      value: sumProducts('denied'),
      name: 'Negado',
    },
    in_use: {
      value: sumProducts('in_use'),
      name: 'Em Uso',
    },
    used: {
      value: sumProducts('used'),
      name: 'Usado',
    },
    expired: {
      value: sumProducts('expired'),
      name: 'Expirado',
    },
  }

  return Object.values(sumResult).filter(index => index.value > 0)
}

const DashboardProducts = props => {
  const [results, setResults] = useState(props.summaryProducts.results)

  useEffect(() => {
    setResults(props.summaryProducts.results)
  }, [props.summaryProducts, props.accountId]);

  return (
    <article className="dashboard">
      <Loading loading={props.isLoading} />

      <Card>
        <div className="inline-items">
          <GenericPieChart
            title="Status dos Produtos"
            subtitle="Produtos"
            data={data(results)}
            colors={chartColors}
          />

          <MyProducts isMainDashboard={props.isMainDashboard} />
        </div>
      </Card>
    </article>
  )
}

DashboardProducts.defaultProps = {
  isLoading: false,
  chart: [],
  results: [],
  isMainDashboard: false
}

DashboardProducts.propTypes = {
  accountId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  chart: PropTypes.array,
  results: PropTypes.array,
  isMainDashboard: PropTypes.bool
}

const mapStateToProps = ({ summaryProducts }) => ({ ...summaryProducts, summaryProducts })

export default connect(mapStateToProps)(withAccountId(DashboardProducts))
