import React, {useState} from "react";
import {fetch} from '../services/fetch'
import store from 'store'
import {Badge, Box, Button, CircularProgress, Divider, IconButton, List, ListItem, ListItemText, Popover, Typography} from "@mui/material";
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux'
import NotificationsIcon from "@mui/icons-material/Notifications";

import {formatDistanceToNow} from "date-fns";
import {ptBR} from "date-fns/locale";

const Notification = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const newNotificationsCount = useSelector(
    (state) => state.auth.newNotificationsCount
  );

  const userData = useSelector(state => state.user)

  const isPopoverOpen = Boolean(anchorEl);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    fetchNotifications();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleViewAll = () => {
    handlePopoverClose();
    history.push('/notifications')
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const request = await fetch(`/users/${userData.results.id}/notifications?limit=4&order=priority&sort=desc`).then(res => res.json());
      const data = request;
      setNotifications(data.results || []);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>

      <IconButton
        color="inherit"
        onClick={handleIconClick}
        sx={{marginRight: "3.5rem"}}
      >
        {notifications.length > 0
          ? (<Badge
            badgeContent={store.get('userNotifyNumber')}
            color="error"
            sx={{
              "& .MuiBadge-badge": {
                fontSize: "1.2rem",
                minWidth: "2rem",
                height: "2rem",
                fontWeight: 700
              },
            }}
          >
            <NotificationsIcon fontSize="large"/>
          </Badge>)
          : <NotificationsIcon fontSize="large"/>}
      </IconButton>


      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        componentsProps={{
          root: {
            sx: {
              "& .MuiPaper-root": {
                width: "35rem",
              },
            },
          },
        }}
      >
        <Typography variant="h6" sx={{padding: 2}}>
          <strong>Notificações</strong>
        </Typography>
        <Divider/>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress/>
          </div>
        ) : notifications.length === 0 ? (
          <Typography variant="p" sx={{padding: 2}}>
            Sem notificações
          </Typography>
        ) : (
          <List sx={{maxHeight: 300, overflow: "auto"}}>
            {notifications.map((notification, index) => (
              <ListItem
                key={index}
                onClick={handleViewAll}
                sx={{
                  "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.08)", cursor: "pointer"},
                }}
                divider
              >
                <Box sx={{display: "flex", width: "100%"}}>
                  {/*<Icon name="mail" icon={faMailBulk} style={{marginRight: "1rem"}}/>*/}
                  <ListItemText
                    // primary={<p style={{fontSize: "1.5rem", borderLeft: "5px solid #1976d2", paddingLeft: "1rem"}}>{notification?.notification?.title}</p>}
                    primary={<p style={{fontSize: "1.5rem"}}>{notification?.notification?.title}</p>}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "1.5rem",
                        fontWeight: 300

                      },
                    }}
                    secondaryTypographyProps={{
                      sx: {
                        fontSize: "1rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      },
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      marginLeft: "auto",
                      whiteSpace: "nowrap",
                      alignSelf: "center",
                      color: "gray",
                      fontSize: "1rem",
                    }}
                  >
                    {formatDistanceToNow(new Date(notification?.notification?.dispatch_at), {
                      addSuffix: true,
                      locale: ptBR,
                    })}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        )}
        <Divider/>
        <Button
          fullWidth
          onClick={handleViewAll}
          sx={{padding: 1}}
        >
          <strong>Ver Todas</strong>
        </Button>
      </Popover>
    </>
  );
};

export default Notification;
