import React from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import {CardGroup, Loading, PageTitle, VulnerabilityApiCard, VulnerabilityLevel} from '../../components'
import {withAccountId} from '../AccountContainer'
import {deleteApplication, fetchApplications, saveSeal} from '../../actions/applications'
import {connect} from 'react-redux'
import {InfiniteScroll} from '../../containers'
import get from 'lodash/get'

class ScanApiList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teste: [{}],
    }
  }

  levelfy(lastScan = {}) {
    const levels = {}
    const summary = get(lastScan, 'report.summary', {})
    Object
      .keys(summary)
      .sort()
      .forEach(item => {
        levels[item] = summary[item].evidences
      })

    return levels
  }

  componentDidMount() {
    if (!this.props.applications.isLoading) {
      this.props.fetchApplications(this.props.accountId, {category: "api"})
    }
  }

  render() {
    const {applications} = this.props

    return (
      <div>
        <section className="applications-list">
          <div className="applications-title-container">
            <PageTitle title="Blindagem de API"/>
          </div>

          <Loading loading={_.isEmpty(applications.results)}/>

          <InfiniteScroll
            actionFetch={this.props.fetchApplications}
            moreParams={{category: 'api'}}
            isLoading={this.props.applications.isLoading}
            finished={this.props.applications.allIds.length >= this.props.applications.count}
          >
            <CardGroup>
              {applications
                .allIds
                .map(id =>
                  <VulnerabilityApiCard
                    key={id}
                    id={id}
                    accountId={this.props.accountId}
                    name={applications.results[id].name}
                    path={applications.results[id].path}
                    url={applications.results[id].url}
                    sealStatus={applications.results[id].seal_status}
                    expirationDate={applications.results[id].expiration_date}
                    position={applications.results[id].position}
                    side={applications.results[id].side}
                    saveSeal={this.props.saveSeal}
                    fetchApplications={this.props.fetchApplications}
                    deleteApplication={this.props.deleteApplication}
                    source={applications.results[id]}
                  >
                    <article>
                      {applications
                        .results[id]
                        .scan_profiles
                        .map((profile) =>
                          <VulnerabilityLevel
                            key={profile.id}
                            scanProfileId={profile.id}
                            scanId={get(profile, 'last_scan.id', '')}
                            name={profile.name}
                            levels={this.levelfy(profile.last_scan)}
                            range={applications.max_evidences}
                            lastScan={get(profile, 'last_scan.completion_date', '')}
                            nextScan={get(profile, 'next_scan.execution_date', '')}
                            runningScan={get(profile, 'running_scan.execution_date', '')}
                            source={applications.results[id]}
                          />)}
                    </article>
                  </VulnerabilityApiCard>)}

              {_.isEmpty(applications.results) && !applications.isLoading &&
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <p>Nenhuma aplicação encontrada.</p>
                </div>
              }
            </CardGroup>
          </InfiniteScroll>
        </section>
      </div>
    )
  }
}

ScanApiList.propTypes = {
  accountId: PropTypes.string.isRequired,
  applications: PropTypes.object.isRequired,
  fetchApplications: PropTypes.func.isRequired,
  deleteApplication: PropTypes.func.isRequired,
  saveSeal: PropTypes.func.isRequired,
  sealStatus: PropTypes.bool,
}

const mapStateToProps = ({applications, dashboardArmour}) => ({
  applications,
  isLoading: dashboardArmour.isLoading,
  sealStatus: dashboardArmour.sealStatus,
  ...dashboardArmour.armour,
})

export default connect(mapStateToProps, {
  fetchApplications,
  saveSeal,
  deleteApplication,
})(withAccountId(ScanApiList))
