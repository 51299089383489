import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux';

import { Header, SideBar, Wootric } from '../components'
import { Dialog, Message, Slider } from '../containers'
import privateRoutes from '../routes/privateRoutes'

export class Main extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      alert: {
        autoClose: true,
      },
      currentPage: '',
    }

    this.alert = this.alert.bind(this)
    this.mountRoutes = this.mountRoutes.bind(this)
  }

  getChildContext () {
    return {
      alert: this.alert,
    }
  }

  setTitle (title) {
    if (window.top !== window.self) {
      window.top.location.href = document.location.href;
    }
    document.title = (title) ? `Site Blindado - ${title}` : 'Site Blindado'
  }

  alert ({ text = '', type = 'error' }) {
    this.setState({
      alert: { text, type },
    })
  }

  mountRoutes (route, index) {

    return (
      <Route
        key={index}
        path={route.path}
        component={props =>
          <route.component
            routes={route.routes}
            setTitle={this.setTitle}
            {...props}
          />
        }
      />
    )
  }

  render () {
    return (
      <section className="app">
        <Header currentPage={this.state.currentPage} />

        <main className="main-content">

          <SideBar />

          <section className="main-section">
            <Switch>
              {privateRoutes.map((route, index) => this.mountRoutes(route, index))}
              <Redirect from="/" to="/dashboard" />
              <Redirect from="*" to="/" />
            </Switch>
          </section>
        </main>

        {/*<Alert {...this.state.alert} onClose={() => this.resetAlertState()} autoClose />*/}
        <Message />
        <Slider />
        <Dialog />

        {this.props.isAuthenticated && <Wootric />}
      </section>
    )
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Main);

Main.childContextTypes = {
  alert: PropTypes.func,
}

Main.propTypes = {
  children: PropTypes.object,
  routes: PropTypes.array,
}
