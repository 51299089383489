import PropTypes from 'prop-types'
import React from 'react'
import {CardGroup, Loading, VulnerabilityCard, VulnerabilityLevel} from '../../components'
import {deleteApplication, fetchApplications, saveSeal} from '../../actions/applications'
import {connect} from 'react-redux'
import get from 'lodash/get'
import _ from 'lodash'
import {withAccountId} from "../AccountContainer";

class ApplicationsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teste: [{}],
    }
  }

  levelfy(lastScan = {}) {
    const levels = {}
    const summary = get(lastScan, 'report.summary', {})
    Object
      .keys(summary)
      .sort()
      .forEach(item => {
        levels[item] = summary[item].evidences
      })

    return levels
  }

  componentDidMount() {
    if (!this.props.applications.isLoading) {
      this.props.fetchApplications(this.props.accountId, {...this.props.fetchApplications.filters})
    }
  }

  render() {
    const {applications, sealStatus} = this.props

    let infoReturn = (
      <div>
        <Loading loading={this.props.isLoading || applications.isLoading}/>
      </div>
    )

    if (sealStatus) {
      infoReturn = (
        <section className="applications-list">
          <Loading loading={this.props.applications.isLoading}/>
          <CardGroup>
            {applications
              .allIds
              .map(id =>
                <VulnerabilityCard
                  key={id}
                  id={id}
                  accountId={this.props.accountId}
                  productId={applications.results[id].product_id}
                  name={applications.results[id].name}
                  path={applications.results[id].path}
                  url={applications.results[id].url}
                  sealStatus={applications.results[id].seal_status}
                  expirationDate={applications.results[id].expiration_date}
                  position={applications.results[id].position}
                  side={applications.results[id].side}
                  saveSeal={this.props.saveSeal}
                  fetchApplications={this.props.fetchApplications}
                  deleteApplication={this.props.deleteApplication}
                  source={applications.results[id]}
                  isAdmin={this.props.auth.isAdmin}
                >
                  <article>
                    {applications
                      .results[id]
                      .scan_profiles
                      .map((profile) =>
                        <VulnerabilityLevel
                          key={profile.id}
                          scanProfileId={profile.id}
                          scanId={get(profile, 'last_scan.id', '')}
                          name={profile.name}
                          levels={this.levelfy(profile?.last_scan)}
                          range={profile?.last_scan?.total_evidences}
                          lastScan={get(profile, 'last_scan.completion_date', '')}
                          nextScan={get(profile, 'next_scan.execution_date', '')}
                          runningScan={get(profile, 'running_scan.execution_date', '')}
                          source={applications.results[id]}
                          requiresAdmin={profile.name.includes("SFA")}
                        />)}
                  </article>
                </VulnerabilityCard>)
            }

            {_.isEmpty(applications.results) && !applications.isLoading &&
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <p>Nenhuma aplicação encontrada.</p>
              </div>
            }
          </CardGroup>
        </section>
      )
    } else {
      infoReturn = (
        <div>
          <Loading loading={this.props.isLoading || applications.isLoading}/>
        </div>
      )
    }

    return (
      <div>
        {infoReturn}
      </div>
    )
  }
}

ApplicationsList.propTypes = {
  auth: PropTypes.shape({
    isAdmin: PropTypes.bool,
  }),
  accountId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  applications: PropTypes.object.isRequired,
  fetchApplications: PropTypes.func.isRequired,
  deleteApplication: PropTypes.func.isRequired,
  saveSeal: PropTypes.func.isRequired,
  sealStatus: PropTypes.bool,
}

const mapStateToProps = ({auth, applications, dashboardArmour}) => ({
  auth,
  applications,
  isLoading: dashboardArmour.isLoading,
  sealStatus: dashboardArmour.sealStatus,
  ...dashboardArmour.armour,
})

export default connect(mapStateToProps, {
  fetchApplications,
  saveSeal,
  deleteApplication,
})(withAccountId(ApplicationsList))
