import {reducer as formReducer} from 'redux-form';

import * as certificates from './certificates';
import * as certifificateOrders from './certificate-orders';
import * as domains from './domains';
import * as form from './form';
import * as organizations from './organizations';
import * as pentestKnowledgebase from './system/pentest-knowledgebase';
import * as pentestReport from './pentest/report';
import * as pentests from './pentest';
import * as product from './product';
import * as products from './products';
import * as orders from './orders';
import * as summaryProducts from './summary-products';
import * as servers from './servers';
import account from './account';
import {applicationDetails, applications, formArmour, scanReport} from './applications';
import {auth} from './auth';
import businessCategories from './business-categories';
import dashboards from './dashboards';
import dashboardArmour from './dashboard-armour';
import dashboardSSL from './dashboard-ssl';
import dialog from './dialog';
import domainValidation from '../modules/domainValidation';
import emailsByDomains from './domains-box';
import evidences from './evidences';
import families from './families';
import messages from '../modules/messages';
import modal from './modal';
import notifications from './notifications';
import pentestEvidence from './pentest/evidence';
import pentestVulnerabilities from './pentest/vulnerabilities';
import promoaccounts from './promoaccounts';
import promocode from './promocode';
import slider from './slider';
import title from './title';
import {uptime} from './uptime';
import uptimechart from './uptime-chart';
import {user, users, usersOrganizations} from './users';
import vulnerabilities from './vulnerabilities';
import wizard from './wizard';
import {awreport} from './awreport';
import {waf} from './waf';
import {salesforce} from "./backoffice/salesforce";
import accountAnalyze from './accountAnalyze';

export const rootReducer = {
  ...certificates,
  ...certifificateOrders,
  ...domains,
  ...form,
  ...organizations,
  ...pentests,
  ...pentestKnowledgebase,
  ...pentestReport,
  ...product,
  ...products,
  ...orders,
  ...summaryProducts,
  ...servers,
  account,
  accountAnalyze,
  salesforce,
  applicationDetails,
  formArmour,
  applications,
  auth,
  awreport,
  businessCategories,
  dashboards,
  dashboardArmour,
  dashboardSSL,
  dialog,
  domainValidation,
  emailsByDomains,
  evidences,
  families,
  form: formReducer,
  messages,
  modal,
  notifications,
  pentestEvidence,
  pentestVulnerabilities,
  promoaccounts,
  promocode,
  scanReport,
  slider,
  title,
  uptime,
  uptimechart,
  user,
  users,
  usersOrganizations,
  vulnerabilities,
  waf,
  wizard,
};
